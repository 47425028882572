import React, { useState, useEffect } from "react";

import DatePicker from "react-datepicker";
import { getDocs, collection, addDoc, where, query } from "firebase/firestore";
import { db } from "../firebaseconfig";

import Select from "react-select";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-datepicker/dist/react-datepicker.css";
import PayNow from "../admin/PayNow";

import Modal from "react-modal";

const customStyles = {
  content: {
    width: "60%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Main = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [totalAmount, setTotalAmount] = useState(null);
  const [dataType, setDataType] = useState("");
  const [dataTypeOptions, setDataTypeOptions] = useState([]);
  const [Basis, setBasis] = useState("");
  const [dataRequired, setDataRequired] = useState("");
  const [dataRequiredOptions, setDataRequiredOptions] = useState([]);
  const [Resolution, setResolution] = useState("");
  const [ResolutionOptions, setResolutionOptions] = useState([]);
  const [Scenario, setScenario] = useState("");
  const [ScenarioOptions, setScenarioOptions] = useState([]);
  const [Station, setStation] = useState([]);
  const [StationOptions, setStationOptions] = useState([]);
  const [StationLists, setStationLists] = useState([]);
  const [Statistics, setStatistics] = useState("");
  const [StatisticsOptions, setStatisticsOptions] = useState([]);
  const [Variable, setVariable] = useState([]);
  const [variableOptions, setVariableOptions] = useState([]);
  const [variableLists, setVariableLists] = useState([]);
  const [duration, setDuration] = useState();
  const [customReason, setCustomReason] = useState("");

  const [resetState, setResetState] = useState(false);

  const [Name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [Organization, setOrganization] = useState("");
  const [endTime, setEndTime] = useState(new Date());
  const [Remarks, setRemarks] = useState("");
  const [Purpose, setPurpose] = useState("");
  const [amount, setAmount] = useState();
  const [VAT, setVAT] = useState();
  const [allowTerms, setAllowTerms] = useState(false);
  const [savedDataid, setSavedDataid] = useState();
  const [showPayNow, setShowPayNow] = useState(false);
  const [ShowTerms, setShowTerms] = useState(false);
  const [integerDuration, setIntegerDuration] = useState();
  const [pricebreakDown, setPricebreakDown] = useState();
  const [iscalculated, setIscalculated] = useState(false);

  const [selectAllStation, setSelectAllStation] = useState(false); // State to track "Select All" for stations
  const [selectAllVariable, setSelectAllVariable] = useState(false); // State to track "Select All" for variables
  const [isWeatherDataSelected, setIsWeatherDataSelected] = useState(false);

  /* const CalculateDuration = (e) => {
    e.preventDefault();
    if (startDate && endTime) {
      // Calculate the difference in milliseconds between the two dates
      const timeDifference = endTime.getTime() - startDate.getTime();

      // Convert milliseconds to years (assuming 365.25 days in a year)
      const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25;
      const durationInYears = timeDifference / millisecondsPerYear;

      const integerDuration = Math.floor(durationInYears);
      setTotalDuration(integerDuration);
    }
  }; */
  const calculateTotalAmount = (e, B, V, S, sD, eD) => {
    e.preventDefault();

    const currentDate = new Date();
    const fifteendaysago = new Date(currentDate);
    fifteendaysago.setDate(currentDate.getDate() - 15);

    const missingFields = [];
    if (startDate > fifteendaysago || endTime > fifteendaysago) {
      missingFields.push(
        "Time Period( Please Select date 15 days prior to current day. )"
      );
    }

    if (dataType == "Weather Report") {
      const maxAllowedDate = new Date(startDate);
      maxAllowedDate.setDate(startDate.getDate() + 15);

      if (endTime > maxAllowedDate) {
        missingFields.push(
          "Time Period (Please select a date range within 15 days duration)"
        );
      }
    }

    if (dataType === "") {
      missingFields.push("Data Type");
    }
    if (Basis === "") {
      missingFields.push("Basis of Data");
    }
    if (Variable.length == 0) {
      missingFields.push("Variable");
    }
    if (Station.length == 0) {
      missingFields.push("Station");
    }

    if (missingFields.length > 0) {
      const errorMessage = (
        <div className="text-left">
          <p className="font-bold text-sm">
            Problems with the following fields:
          </p>
          <hr />
          <ol>
            {missingFields.map((field, index) => (
              <li key={index} className="p-1 text-xs">
                {index + 1}. {field}
              </li>
            ))}
          </ol>
        </div>
      );
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setIscalculated(true);
      const vSize = V.length;
      const sSize = S.length;
      let tA = 0;
      let vat = 0;
      let total = 0;
      let yearCount;

      const timeDifference = eD.getTime() - sD.getTime();

      const millisecondsPerYear = 1000 * 60 * 60 * 24;
      const daysDifference = timeDifference / millisecondsPerYear;

      const intDuration = Math.round(daysDifference / 365.25);
      setIntegerDuration(intDuration);

      if (intDuration >= 1 && intDuration <= 5) {
        yearCount = Math.ceil(intDuration / 5); // Divide by 5 and round up
      } else {
        yearCount = Math.ceil(intDuration / 5); // Divide by 5 and round down
      }

      yearCount = Math.max(1, yearCount);
      setDuration(yearCount);

      if (B === "3 Hour") {
        tA = 1000 * vSize + sSize * 200 + 200 * yearCount;
        vat = tA * 0.15;
        setAmount(tA);
        total = tA + vat;
        setTotalAmount(total);

        setVAT(vat);
        setPricebreakDown(
          ` Fee Amount: ${tA} Tk + VAT 15%: ${vat} Tk | Total: ${total} Tk.`
        );
      } else if (B === "Daily") {
        tA = 800 * vSize + sSize * 100 + 100 * yearCount;
        vat = tA * 0.15;
        setAmount(tA);
        total = tA + vat;
        setTotalAmount(total);
        setVAT(vat);
        setPricebreakDown(
          ` Fee Amount: ${tA} Tk + VAT 15%: ${vat} Tk | Total: ${total} Tk.`
        );
      } else if (B === "Monthly") {
        tA = 500 * vSize + sSize * 100 + 100 * yearCount;
        vat = tA * 0.15;
        setAmount(tA);
        total = tA + vat;
        setTotalAmount(total);
        setVAT(vat);
        setPricebreakDown(
          ` Fee Amount: ${tA} Tk + VAT 15%: ${vat} Tk | Total: ${total} Tk.`
        );
      }
    }
  };
  const dateConverter = (d) => {
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-indexed
    const year = d.getFullYear();
    const convertedDate = `${day}/${month}/${year}`;
    return convertedDate;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const missingFields = [];

    if (Name === "") {
      missingFields.push("Customer Name");
    }
    if (phone === "") {
      missingFields.push("Customer Phone");
    }
    if (email === "") {
      missingFields.push("Customer Email");
    }
    if (!iscalculated) {
      missingFields.push("Price Calculation Required");
    }

    if (dataRequired === "") {
      missingFields.push("Data Required");
    }
    if (allowTerms == false) {
      missingFields.push("Please accept terms and conditions");
    }
    if (missingFields.length > 0) {
      const errorMessage = (
        <div className="text-left">
          <p className="font-bold text-sm">
            Problems with the following fields:
          </p>
          <hr />
          <ol>
            {missingFields.map((field, index) => (
              <li key={index} className="p-1 text-xs">
                {index + 1}. {field}
              </li>
            ))}
          </ol>
        </div>
      );
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      calculateTotalAmount(e, Basis, Variable, Station, startDate, endTime);
      const currentDate = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const convertedStartDate = dateConverter(startDate);
      const convertedendDate = dateConverter(endTime);
      const data = {
        Date: startDate,
        dataType: dataType,
        BasisOfData: Basis,
        DataRequired: dataRequired === "Others" ? customReason : dataRequired,
        Resolution: Resolution,
        Scenarios: Scenario,
        Stations: Station,
        Variables: Variable,
        Statistics: Statistics,
        Name: Name,
        Phone: phone,
        Email: email,
        Organization: Organization,
        EndTime: convertedendDate,
        StartTime: convertedStartDate,
        Remarks: Remarks,
        Purpose: Purpose,
        terms: allowTerms,
        isPaid: false,
        totalAmount: totalAmount,
        OrderTime: currentDate.toLocaleString("en-US", options),
        intDuration: integerDuration,
        amountBreakdouwn: pricebreakDown,
      };
      const dataToSave = addDoc(collection(db, "FormData"), data).then(
        async (docRef) => {
          setSavedDataid(docRef.id);
          toast.success(
            "Your Request has been delivered. Please wait for payment option.",
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );

          // Send email to user

          const userEmail = email; // or whichever email you want to send to
          const emailSubject = `BMD Confirmation Mail - ${Name}`;
          const emailSubject2 = `Data Portal Order From - ${Name}`;
          const emailHTML1 = `
          <html>
            <head>
              <style>
                /* Add your CSS styles here */
              </style>
            </head>
            <body>
              <div>
                <h3>BMD Data Portal</h3>
                <p><b>Order Details</b></p>
                <hr />
                <h5>Name: ${Name}</h5>
                <h5>Phone: ${phone}</h5>
                <h5>Email: ${email}</h5>
                <h5>Organization: ${Organization}</h5>
                <h5>Category Choosen: ${dataType}</h5>
                <h5>Basis of Data: ${Basis}</h5>
                <h5>Time Duration: ${convertedStartDate} to ${convertedendDate} =  ${integerDuration}  Year(s)</h5>
                <h5>Selected Variables: ${Variable.map(
                  (variable) => variable.label
                ).join(", ")}</h5>
        <h5>Selected Stations: ${Station.map((station) => station.label).join(
          ", "
        )}</h5>
                
                <h5>Purpose: ${dataRequired}</h5>

                <h5>Total Amount: ${pricebreakDown}</h5>
                <h5>Payment Status: Pending..</h5>
                <hr/>
                <p>Your request has been received. We will contact you soon. Thank you</p>
                
              </div>
            </body>
          </html>
        `;

          try {
            const userResponse = await fetch(
              "https://weatherbmd-api.onrender.com/send-email",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  toEmail: userEmail,
                  subject: emailSubject,
                  html: emailHTML1,
                }),
              }
            );

            if (userResponse.ok) {
              console.log("Email sent to user successfully.");
            } else {
              console.error("Error sending email to user.");
            }
          } catch (error) {
            console.error("Error:", error);
          }

          // Send email to admin

          const emailHTML2 = `
          <html>
            <head>
              <style>
                /* Add your CSS styles here */
              </style>
            </head>
            <body>
              <div>
                <h3>BMD Data Portal</h3>
                <hr />
                <h5>Name: ${Name}</h5>
                <h5>Phone: ${phone}</h5>
                <h5>Email: ${email}</h5>
                <h5>Organization: ${Organization}</h5>
                <h5>Category Choosen: ${dataType}</h5>
                <h5>Basis of Data: ${Basis}</h5>
                <h5>Time Duration: ${convertedStartDate} to ${convertedendDate} =  ${integerDuration}  Year(s)</h5>
                <h5>Selected Variables: ${Variable.map(
                  (variable) => variable.label
                ).join(", ")}</h5>
        <h5>Selected Stations: ${Station.map((station) => station.label).join(
          ", "
        )}</h5>
                <h5>Purpose: ${dataRequired}</h5>
                <h5>Total Amount: ${pricebreakDown}</h5>
                <h5>Payment Status: Pending..</h5>
                <p>Auto Generate Mail From BMD DATA PORTAL</p>
                
              </div>
            </body>
          </html>
        `;
          const adminResponse = await fetch(
            "https://weatherbmd-api.onrender.com/send-email",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                toEmail: "bmdclimate24@gmail.com",
                //toEmail: "fahimferdous119@gmail.com",
                subject: emailSubject2,
                html: emailHTML2,
              }),
            }
          );

          if (adminResponse.ok) {
            console.log("Email sent to admin successfully.");
          } else {
            console.error("Error sending email to admin.");
          }

          setBasis("");
          setName("");
          setEmail("");
          setOrganization("");
          setPhone("");
          setRemarks("");
          setPurpose("");
          setVariable("");
          setResolution("");
          setStation("");
          setDataRequired("");
          setCustomReason("");
          setDataType("");
          setScenario("");
          setStatistics("");
          setStartDate("");
          setEndTime("");
          setAllowTerms("");
          setShowPayNow(true);
        }
      );
    }
  };

  useEffect(() => {
    const fetchDataType = async () => {
      try {
        const datatypeSnapshot = await getDocs(collection(db, "dataTypes"));
        setDataTypeOptions(datatypeSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching data options:", error);
      }
    };

    const fetchStations = async () => {
      try {
        const stationSnapshot = await getDocs(collection(db, "Station"));

        setStationOptions(stationSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching station options:", error);
      }
    };

    const fetchDataRequired = async () => {
      try {
        const stationSnapshot = await getDocs(collection(db, "Datarequired"));
        setDataRequiredOptions(stationSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching station options:", error);
      }
    };

    const fetchResolutions = async () => {
      try {
        const stationSnapshot = await getDocs(collection(db, "Resolution"));
        setResolutionOptions(stationSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching station options:", error);
      }
    };

    const fetchScenarios = async () => {
      try {
        const stationSnapshot = await getDocs(collection(db, "Scenario"));
        setScenarioOptions(stationSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching station options:", error);
      }
    };

    const fetchStatistics = async () => {
      try {
        const stationSnapshot = await getDocs(collection(db, "Statistics"));
        setStatisticsOptions(stationSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching station options:", error);
      }
    };

    fetchDataType();
    fetchStations();
    fetchResolutions();
    fetchDataRequired();
    fetchStations();
    fetchStatistics();

    fetchScenarios();
  }, []);
  const convertToOptions = (firebaseData) => {
    return firebaseData.map((item) => ({
      value: item.label ? item.label.toLowerCase().replace(" ", "_") : "",
      label: item.label,
    }));
  };
  useEffect(() => {
    setVariableLists(convertToOptions(variableOptions));
  }, [variableOptions]);

  useEffect(() => {
    setStationLists(convertToOptions(StationOptions));
  }, [StationOptions]);

  const handleSelectAllVariable = () => {
    if (!selectAllVariable) {
      setVariable(variableLists); // Select all variables
    } else {
      setVariable([]); // Deselect all variables
    }
    setSelectAllVariable(!selectAllVariable); // Toggle the "Select All" state
  };

  const handleSelectAllStation = () => {
    if (!selectAllStation) {
      setStation(StationLists); // Select all stations
    } else {
      setStation([]); // Deselect all stations
    }
    setSelectAllStation(!selectAllStation); // Toggle the "Select All" state
  };

  const fetchVariables = async (selectedDataType) => {
    try {
      // You need to replace "VariableCollection" with the actual collection name
      const variableCollection = collection(db, "variable");
      const variableQuery = query(
        variableCollection,
        where("dataTypeName", "==", selectedDataType)
      );
      const variableSnapshot = await getDocs(variableQuery);

      const variableList = variableSnapshot.docs.map((doc) => ({
        label: doc.data().dataType, // Update with your variable field name
      }));

      setVariableOptions(variableList);
    } catch (error) {
      console.error("Error fetching variables: ", error);
    }
  };

  const fetchStations = async (selectedDataType) => {
    try {
      // You need to replace "VariableCollection" with the actual collection name
      const variableCollection = collection(db, "Station");
      const variableQuery = query(
        variableCollection,
        where("dataTypeName", "==", selectedDataType)
      );
      const variableSnapshot = await getDocs(variableQuery);

      const variableList = variableSnapshot.docs.map((doc) => ({
        label: doc.data().dataType, // Update with your variable field name
      }));

      setStationOptions(variableList);
    } catch (error) {
      console.error("Error fetching variables: ", error);
    }
  };

  useEffect(() => {
    fetchVariables(dataType);
    fetchStations(dataType);
  }, [dataType]);

  const handleDataTypeChange = (newDataType) => {
    // Reset the states when data type changes
    setStation([]);
    setVariable([]);
    setDataType(newDataType);
    if (newDataType === "Weather Report") {
      setIsWeatherDataSelected(true);
    } else {
      setIsWeatherDataSelected(false);
    }
    setResetState(!resetState); // Toggle resetState to force re-render and clear Select components
  };

  return (
    <section className="w-[90%] ml-[5%] mt-[2%] flex gap-5 mb-5">
      <ToastContainer />
      <div className="w-[50%]">
        <div className="flex justify-between">
          <p className="text-[16px] font-bold text-[#c21ec2]">
            Climate and Weather Data Purchase
          </p>
        </div>
        <form className="">
          <div className="flex gap-5">
            <div className="w-[50%] text-left pt-3">
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Customer Name<span className="text-red-600 font-bold">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Customer Name"
                  required
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                />
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Mobile Number<span className="text-red-600 font-bold">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Mobile Number"
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                />
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Data Type<span className="text-red-600 font-bold">*</span>
                </label>
                <select
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                  onChange={(e) => handleDataTypeChange(e.target.value)}
                  required
                  value={dataType}
                >
                  <option>Select..</option>
                  {dataTypeOptions &&
                    dataTypeOptions.map((item, index) => (
                      <option key={index}>{item.dataType}</option>
                    ))}
                </select>
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Time Period (From)
                  <span className="text-red-600 font-bold">*</span>
                </label>
                <DatePicker
                  className="appearance-none block border border-gray-600 rounded-xl p-2 w-[295px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  selected={startDate}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setStartDate(date)}
                />
              </div>
            </div>
            <div className="w-[50%] text-left pt-3">
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Office/Organization
                </label>
                <input
                  type="text"
                  placeholder="Office/Organization"
                  value={Organization}
                  onChange={(e) => setOrganization(e.target.value)}
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                />
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Email Address<span className="text-red-600 font-bold">*</span>
                </label>
                <input
                  type="email"
                  placeholder="Email Address"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                />
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Basis Of Data<span className="text-red-600 font-bold">*</span>
                </label>
                <select
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                  value={Basis}
                  required
                  onChange={(e) => setBasis(e.target.value)}
                  disabled={!dataType}
                >
                  <option aria-readonly>Select...</option>
                  {dataType == "Upper Air Data" ||
                  dataType == "Cyclone Data" ||
                  dataType == "Tornado Data" ||
                  dataType == "Seismic Data" ||
                  dataType == "Astronomical Data" ? (
                    <>
                      <option>Daily</option>
                    </>
                  ) : (
                    <>
                      <option>3 Hour</option>
                      <option>Daily</option>
                      <option>Monthly</option>
                    </>
                  )}
                </select>
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Time Period (To)
                  <span className="text-red-600 font-bold">*</span>
                </label>
                <DatePicker
                  className="appearance-none block border border-gray-600 rounded-xl p-2 w-[295px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  selected={endTime}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setEndTime(date)}
                />
              </div>
            </div>
          </div>
          <p className="text-left text-[16px] font-bold text-[#c21ec2]">
            Note: Apply only for 15 days before from current date.
          </p>
          <div className="w-[98%] pb-5 text-left pt-3">
            <label htmlFor="" className="font-semibold">
              Variable/Data<span className="text-red-600 font-bold">*</span>
            </label>
            <Select
              //options={variableLists}
              className={isWeatherDataSelected ? "disable-remove" : ""}
              options={
                isWeatherDataSelected
                  ? [
                      { label: "Select All", value: "select_all_variables" },
                      ...variableLists.map((item) => ({
                        ...item,
                        isDisabled: true,
                      })),
                    ]
                  : [
                      // Add the "Select All" option for variables
                      { label: "Select All", value: "select_all_variables" },
                      ...variableLists,
                    ]
              }
              value={Variable}
              defaultValue={null}
              required
              disabled={!dataType}
              key={resetState}
              onChange={(options) => {
                if (
                  options &&
                  options.length > 0 &&
                  options[0].value === "select_all_variables"
                ) {
                  handleSelectAllVariable(); // Handle "Select All" for variables
                } else {
                  setVariable(options);
                }
              }}
              isMulti={true}
              isClearable={!isWeatherDataSelected} // Disable clear for weather data
              closeMenuOnSelect={!isWeatherDataSelected} // Optionally keep the menu open when selecting multiple items for weather data
            />
          </div>
          <div className="w-[98%] pb-5 text-left pt-3">
            <label htmlFor="" className="font-semibold">
              Station<span className="text-red-600 font-bold">*</span>
            </label>

            <Select
              //options={StationLists}
              options={[
                // Add the "Select All" option for stations
                { label: "Select All", value: "select_all_stations" },
                ...StationLists,
              ]}
              value={Station}
              required
              disabled={!dataType}
              key={resetState}
              defaultValue={null}
              onChange={(options) => {
                if (
                  options &&
                  options.length > 0 &&
                  options[0].value === "select_all_stations"
                ) {
                  handleSelectAllStation(); // Handle "Select All" for stations
                } else {
                  setStation(options);
                }
              }}
              isMulti={true}
            />
          </div>
          <div className="flex justify-end">
            {totalAmount && (
              <p className="p-2 font-semibold">
                Fee Amount: {amount} Tk + VAT 15%: {VAT} Tk | Total:{" "}
                {totalAmount} Tk.
              </p>
            )}
            <button
              className="p-3 rounded-lg bg-blue-500 text-white font-semibold text-right"
              onClick={(e) =>
                calculateTotalAmount(
                  e,
                  Basis,
                  Variable,
                  Station,
                  startDate,
                  endTime
                )
              }
            >
              Calculate Price
            </button>
          </div>
        </form>
        <form>
          <div className="w-[98%] pb-5 pt-3 text-left">
            <label htmlFor="" className="font-semibold">
              Remarks
            </label>
            <input
              type="text"
              placeholder="Remarks"
              value={Remarks}
              onChange={(e) => setRemarks(e.target.value)}
              className="border border-gray-600 rounded-xl p-2 w-[100%]"
            />
          </div>

          <div className="flex gap-5">
            <div className="w-[50%] text-left pt-3">
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Data Required For
                  <span className="text-red-600 font-bold">*</span>
                </label>
                <select
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                  onChange={(e) => setDataRequired(e.target.value)}
                  required
                  value={dataRequired}
                >
                  <option aria-readonly>Select Reason...</option>
                  <option>Development</option>
                  <option>Research</option>
                  <option>Thesis</option>
                  <option>Others</option>
                </select>
              </div>
              {dataRequired === "Others" && (
                <div className="w-[98%] pb-5">
                  <label htmlFor="" className="font-semibold">
                    Specify Reason
                  </label>
                  <input
                    type="text"
                    className="border border-gray-600 rounded-xl p-2 w-[100%]"
                    placeholder="write the reason"
                    value={customReason}
                    onChange={(e) => setCustomReason(e.target.value)}
                  />
                </div>
              )}

              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Scenario
                </label>
                <select
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                  onChange={(e) => setScenario(e.target.value)}
                  value={Scenario}
                >
                  <option>Select Scenario...</option>
                  {ScenarioOptions &&
                    ScenarioOptions.map((item, index) => (
                      <option key={index}>{item.dataType}</option>
                    ))}
                </select>
              </div>
            </div>
            <div className="w-[50%] text-left pt-3">
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Resolution
                </label>
                <select
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                  onChange={(e) => setResolution(e.target.value)}
                  value={Resolution}
                >
                  <option>Select Resolution...</option>
                  {ResolutionOptions &&
                    ResolutionOptions.map((item, index) => (
                      <option key={index}>{item.dataType}</option>
                    ))}
                </select>
              </div>
              <div className="w-[98%] pb-5">
                <label htmlFor="" className="font-semibold">
                  Statistics
                </label>
                <select
                  className="border border-gray-600 rounded-xl p-2 w-[100%]"
                  onChange={(e) => setStatistics(e.target.value)}
                  value={Statistics}
                >
                  <option>Select Statistics...</option>
                  {StatisticsOptions &&
                    StatisticsOptions.map((item, index) => (
                      <option key={index}>{item.dataType}</option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          <div className="w-[98%] pb-5 pt-3 text-left">
            <label htmlFor="" className="font-semibold">
              Special Note
            </label>
            <input
              type="text"
              placeholder="Write a note"
              value={Purpose}
              onChange={(e) => setPurpose(e.target.value)}
              className="border border-gray-600 rounded-xl p-2 w-[100%]"
            />
          </div>
          <p className="text-left pb-1 text-[16px] font-bold text-[#c21ec2]">
            Note: It will take a minimum of five working days or more to deliver
            the required data.
          </p>
          <div className="flex justify-between">
            <div className="flex">
              <input
                id="default-checkbox"
                type="checkbox"
                checked={allowTerms}
                onChange={() => setAllowTerms(true)}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="default-checkbox "
                class="ml-2 text-blue-600 font-semibold text-[14px] cursor-pointer"
                onClick={() => setShowTerms(true)}
              >
                Terms and Conditions
                <span className="text-red-600 font-bold">*</span>
              </label>
            </div>

            <button
              className="p-2 rounded-lg bg-blue-500 text-white text-[14px] font-semibold text-right"
              onClick={handleSubmit}
              //disabled={!allowTerms}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <div className="w-[50%]">
        <div className="flex justify-between pb-3">
          <p className="text-[16px] font-bold text-[#c21ec2]">
            GoB Approved Rate of Climate and Weather Data
          </p>
          {/* <p className="text-[14px] font-bold text-white p-2 bg-blue-500 rounded-xl cursor-pointer">
            Pay Now
          </p> */}
        </div>
        <table className="w-[100%] table pt-3 pb-3">
          <thead>
            <tr className="">
              <th className="bg-gray-400 border-gray-700 border p-1">S.N.</th>
              <th className="bg-gray-400 border-gray-700 border p-1">Item</th>
              <th className="bg-gray-400 border-gray-700 border p-1">Number</th>
              <th className="bg-gray-400 border-gray-700 border p-1">
                3-Hour Data
              </th>
              <th className="bg-gray-400 border-gray-700 border p-1">Daily</th>
              <th className="bg-gray-400 border-gray-700 border p-1">
                Monthly
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-700 p-1">1</td>
              <td className="border border-gray-700 p-1">Any Item</td>
              <td className="border border-gray-700 p-1">1</td>
              <td className="border border-gray-700 p-1">Tk 1000.00</td>
              <td className="border border-gray-700 p-1">Tk 800.00</td>
              <td className="border border-gray-700 p-1">Tk 500.00</td>
            </tr>
            <tr>
              <td className="border border-gray-700 p-1">2</td>
              <td className="border border-gray-700 p-1">Number of Stations</td>
              <td className="border border-gray-700 p-1">1</td>
              <td className="border border-gray-700 p-1">Tk 200.00</td>
              <td className="border border-gray-700 p-1">Tk 100.00</td>
              <td className="border border-gray-700 p-1">Tk 100.00</td>
            </tr>
            <tr>
              <td className="border border-gray-700 p-1">3</td>
              <td className="border border-gray-700 p-1">Time</td>
              <td className="border border-gray-700 p-1">Every 5 years</td>
              <td className="border border-gray-700 p-1">Tk 200.00</td>
              <td className="border border-gray-700 p-1">Tk 100.00</td>
              <td className="border border-gray-700 p-1">Tk 100.00</td>
            </tr>
          </tbody>
        </table>
        <p className="pt-3 font-semibold text-left text-[16px]">
          Note: Charge and 15%VAT/TAX will be Payable
        </p>
        <p className="text-[16px] font-bold text-[#c21ec2] pt-3 text-left">
          Transaction Gateways
        </p>
        {/* <table className="w-[100%] table pt-3 pb-3">
          <thead>
            <tr>
              <th className="bg-gray-400 border-gray-700 border p-1">Method</th>
              <th className="bg-gray-400 border-gray-700 border p-1">Charge</th>
              <th className="bg-gray-400 border-gray-700 border p-1">Method</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-700 p-1  pl-[5%]">
                <img className="w-28" src="images/VISA.png" />
              </td>
              <td className="border border-gray-700 p-1 pl-[5%]">
                <img className="w-28" src="images/mastercard.png" />
              </td>
              <td className="border border-gray-700 p-1 pl-[5%]">
                <img className="w-28" src="images/aex.png" />
              </td>
            </tr>
          </tbody>
        </table> */}
        <img className="pt-3 pb-3" src="/images/paymentGateway.jpg" />
        <p className="text-[16px] font-bold text-[#c21ec2] pt-3 text-left">
          Help Line
        </p>
        <table className="w-[100%] text-left pt-3 pb-3">
          <thead>
            <tr>
              <th className="bg-gray-400 border p-3">
                Md. Mazibor Rahman:{" "}
                <span className="font-normal">+8801552446052</span>
              </th>
            </tr>

            <tr>
              <th className="bg-gray-400 border p-3">
                Fahmida Haris: <span className="font-normal">+01912349579</span>
              </th>
            </tr>
            <tr>
              <th className="bg-gray-400 border p-3">
                Shahanara Akter:{" "}
                <span className="font-normal">+01846158818</span>
              </th>
            </tr>
            <tr>
              <th className="bg-gray-50 border p-3">
                Emergency Contact:{" "}
                <span className="font-normal">+88-02-41025703</span>
              </th>
            </tr>
            <tr>
              <th className="bg-gray-400 border p-3">
                Email:{" "}
                <span className="font-normal">
                  bmdclimate24@gmail.com & info@bmd.gov.bd
                </span>
              </th>
            </tr>
          </thead>
        </table>
        <p className="text-[16px] font-bold text-[#c21ec2] pt-3 text-left">
          Terms and Conditions*
        </p>
        <hr className="w-full border-[0.5px] border-gray-400 mt-1 mb-1" />
        <p className="text-justify text-sm ">
          Bangladesh Meteorological Department has certain rules and regulations
          for providing meteorological data which are directed and approved by
          the Ministry of Defence. According to the Parliamentary Law of the
          People’s Republic of Bangladesh Act No. XXVIII of 2018, under Chapter
          II section 6. “Functions of the Department”- (1) the sub-article (vi)
          of article (a) of the responsibilities and functions of the
          departments, and section 7. “Duties regarding meteorological
          services”- (1) and (2) of powers of the function of departments in the
          same chapter, Bangladesh Meteorological Department has been performing
          its responsibilities as the only reliable organization with official
          jurisdiction. The Climate division in this department is a
          meteorological data storage and supply organization which along with
          storing data also provides meteorological data for related purposes in
          exchange for a fixed amount of fee fixed by the government. Use,
          distribution, dissemination, and publishing of the said meteorological
          data for any other purposes except for the mentioned ones is entirely
          against the Meteorological Act.
        </p>
      </div>

      <Modal
        isOpen={ShowTerms}
        style={customStyles}
        contentLabel="Terms and Conditions"
      >
        <div className="flex justify-between p-2">
          <p className="text-[16px] font-bold text-[#c21ec2] pt-3 text-left">
            Terms and Conditions*
          </p>
          <button
            className="border border-gray-500 rounded-xl p-2"
            onClick={() => setShowTerms(false)}
          >
            close
          </button>
        </div>
        <hr className="w-full border-[0.5px] border-gray-400 mt-1 mb-1" />
        <section className="p-3 m-3">
          <p className="text-justify text-sm ">
            Bangladesh Meteorological Department has certain rules and
            regulations for providing meteorological data which are directed and
            approved by the Ministry of Defence. According to the Parliamentary
            Law of the People’s Republic of Bangladesh Act No. XXVIII of 2018,
            under Chapter II section 6. “Functions of the Department”- (1) the
            sub-article (vi) of article (a) of the responsibilities and
            functions of the departments, and section 7. “Duties regarding
            meteorological services”- (1) and (2) of powers of the function of
            departments in the same chapter, Bangladesh Meteorological
            Department has been performing its responsibilities as the only
            reliable organization with official jurisdiction. The Climate
            division in this department is a meteorological data storage and
            supply organization which along with storing data also provides
            meteorological data for related purposes in exchange for a fixed
            amount of fee fixed by the government. Use, distribution,
            dissemination, and publishing of the said meteorological data for
            any other purposes except for the mentioned ones is entirely against
            the Meteorological Act.
          </p>
        </section>

        <hr />
      </Modal>
      <Modal
        isOpen={showPayNow}
        style={customStyles}
        contentLabel="BMD Data Portal"
      >
        <div className="flex justify-between p-2">
          <p className="pt-2 font-semibold text-[16px] ">BMD Data Portal</p>
          <button
            className="border border-gray-500 rounded-xl p-2"
            onClick={() => setShowPayNow(false)}
          >
            close
          </button>
        </div>
        <hr />
        <PayNow amount={totalAmount} dataid={savedDataid} />
      </Modal>
    </section>
  );
};

export default Main;
