import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const PaymentCancel = () => {
  const navigate = useNavigate();
  return (
    <section className="w-[90%] ml-[5%] mt-[5%] flex gap-5 mb-5">
      <>
        <p className="text-red-700 font-semibold text-14">
          An Error Occured. Payment Cancelled.
        </p>
        <button
          className="p-2 rounded-xl bg-blue-600 text-white font-semibold"
          onClick={() => navigate("/")}
        >
          Go to Homepage
        </button>
      </>
    </section>
  );
};

export default PaymentCancel;
