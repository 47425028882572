import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const SuccessfulPayment = () => {
  const navigate = useNavigate();

  return (
    <section className="w-[90%] ml-[5%] mt-[5%] flex gap-5 mb-5">
      <>
        <p className="text-green-700 font-semibold text-14">
          Payment Successfull. An Email has been Sent!
        </p>
        <button
          className="p-2 rounded-xl bg-blue-600 text-white font-semibold"
          onClick={() => navigate("/")}
        >
          Go to Homepage
        </button>
      </>
    </section>
  );
};

export default SuccessfulPayment;
