import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../contexts/AuthContext"; // Adjust the import path accordingly

const Header = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.error("Logout failed", error);
    }
  };
  return (
    <div>
      <section className="w-[90%] ml-[5%] h-full flex flex-col justify-center ">
        <img src="images/Untitled-2.jpg" className="" />
        <div className="bg-gray-400 font-semibold w-full h-[40px] p-2 flex gap-3">
          <Link className="cursor-pointer hover:text-gray-50">
            Climate Data Portal
          </Link>
          {currentUser ? (
            <button
              onClick={handleLogout}
              className="cursor-pointer hover:text-gray-50"
            >
              Logout
            </button>
          ) : (
            <Link to="/login" className="cursor-pointer hover:text-gray-50">
              Login
            </Link>
          )}
        </div>
      </section>
    </div>
  );
};

export default Header;
