import React, { useState, useEffect } from "react";
import { db } from "../firebaseconfig";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Pagination from "react-js-pagination";

import { collection, getDocs } from "firebase/firestore";
const TransactionHistory = () => {
  const [dataList, setDataList] = useState([]);
  const fetchData = async () => {
    try {
      const dataCollection = collection(db, "FormData");
      const dataSnapshot = await getDocs(dataCollection);
      const dataList = dataSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dataList.sort((a, b) => new Date(b.OrderTime) - new Date(a.OrderTime));

      setDataList(dataList);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //Pagination
  const [currentAirPage, setCurrentAirPage] = useState(1);
  const AirreportsPerPage = 12;

  const indexOfLastAirUser = currentAirPage * AirreportsPerPage;
  const indexOfFirstAirUser = indexOfLastAirUser - AirreportsPerPage;
  const currentAirList = dataList.slice(
    indexOfFirstAirUser,
    indexOfLastAirUser
  );
  return (
    <div className="bg-gray-50 text-left p-3 w-full">
      <h5 className="text-center font-semibold ">Transaction History</h5>
      <hr />
      <table className="w-[100%] table mt-2 pt-3 pb-3 text-xs">
        <thead>
          <tr className="">
            <th className="bg-gray-400 border-gray-700 border p-1">SL</th>
            <th className="bg-gray-400 border-gray-700 border p-1">Name</th>
            <th className="bg-gray-400 border-gray-700 border p-1">
              Mobile Number
            </th>
            <th className="bg-gray-400 border-gray-700 border p-1">Email</th>
            <th className="bg-gray-400 border-gray-700 border p-1">
              Order Date
            </th>
            <th className="bg-gray-400 border-gray-700 border p-1">
              Total Amount
            </th>
            <th className="bg-gray-400 border-gray-700 border p-1 w-48">
              Payment Status
            </th>
            {/*  <th className="bg-gray-400 border-gray-700 border p-1">Paid</th>
            <th className="bg-gray-400 border-gray-700 border p-1">Not Paid</th> */}
          </tr>
        </thead>
        <tbody>
          {dataList &&
            currentAirList.map((item, index) => (
              <tr>
                <td className="border border-gray-700 p-1">{index + 1}</td>
                <td className="border border-gray-700 p-1">{item.Name}</td>
                <td className="border border-gray-700 p-1">{item.Phone}</td>
                <td className="border border-gray-700 p-1">{item.Email}</td>
                <td className="border border-gray-700 p-1 text-center">
                  {item.OrderTime}
                </td>
                <td className="border border-gray-700 p-1 text-center">
                  {item.totalAmount}
                </td>
                <td
                  className={`w-48 border border-gray-700 p-1 flex ${
                    item.isPaid ? "justify-start" : "justify-end"
                  }`}
                >
                  <p
                    className={`w-[45%] text-center ${
                      item.isPaid
                        ? "border-blue-500 border p-1 rounded-xl text-gray-800 font-semibold "
                        : "border-red-500 border p-1 rounded-xl text-gray-800 font-semibold"
                    }`}
                  >
                    {item.isPaid ? "Paid" : "Not Paid"}
                  </p>
                </td>

                {/* <td className="border border-gray-700 p-1 flex justify-center">
                  {item.isPaid == "true" ? (
                    <p className="w-[70%] text-center border-blue-500 border p-1 rounded-xl text-gray-800 font-semibold">
                      Paid
                    </p>
                  ) : (
                    ""
                  )}
                </td>
                <td className="border border-gray-700 p-1 flex justify-center">
                  {item.isPaid == "false" ? (
                    ""
                  ) : (
                    <p className="w-[70%] text-center border-red-500 border p-1 rounded-xl text-gray-800 font-semibold">
                      Not Paid
                    </p>
                  )}
                </td> */}
              </tr>
            ))}
        </tbody>
      </table>
      <div className="pagination flex  justify-start mt-4 ">
        <Pagination
          activePage={currentAirPage}
          itemsCountPerPage={AirreportsPerPage}
          totalItemsCount={dataList.length}
          pageRangeDisplayed={5}
          onChange={setCurrentAirPage}
          itemClass="inline-block mx-1 rounded-xl bg-gray-800 text-white p-2 text-[10px]"
          linkClass="block"
          activeLinkClass="text-gray-200"
          disabledClass="opacity-50 cursor-not-allowed"
        />
      </div>
    </div>
  );
};

export default TransactionHistory;
